//@flow
//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { alertActions } from '../../_actions/alert.action';
import { objectsConstants } from '../../_constants/objects.constants';
import { formatterHelper } from '../../_helpers/formatter.helper';
import { translate } from '../../_helpers/messages.helper';
import { cooperativaService } from '../../_services/cooperativa.service';
import { defaultService } from '../../_services/defaultService';
import { estabelecimentoService } from '../../_services/estabelecimento.service';
import { midiaService } from '../../_services/midia.service';
import ApiIBGE from '../../_services/uf.service';
import { whatsappService } from '../../_services/whatsapp.service';
import { FormGroup } from '../../components/FormGroup';
import { HorarioFuncionamento } from '../../components/HorarioFuncionamento/HorarioFuncionamento';
import CheckBoxInputOnly from '../../components/inputs/CheckBoxInputOnly';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import FormInput from '../../components/inputs/FormInput';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import ImageInput from '../../components/inputs/ImageInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import SelectInput from '../../components/inputs/SelectInput';
import ZipCodeInput from '../../components/inputs/ZipCodeInput';
import { ModalWhatsapp } from '../../components/Modal/ModalWhatsapp';
import MultiTelefones from '../../components/MultiTelefones/MultiTelefones';
import imagemBG from '../../images/bgImage.jpeg';

type Props = {
  match: any,
  nome?: string,
  callbackOnSave?: any,
};

type State = {
  id?: number,
  showModalWhatsapp: boolean,
  qrCode: any,
};

const EstabelecimentoValidate = Yup.object().shape({
  nome: Yup.string()
    .min(3, 'Nome muito curto!')
    .max(60, 'Nome muito grande!')
    .required('Obrigatório'),
  email: Yup.string().nullable().notRequired().email('Email inválido'),
  cpfCnpj: Yup.string().required('Obrigatório'),
  tipoIntegracao: Yup.string().required('Obrigatório'),
  exibirBtnCompareceuParaProf: Yup.string().required('Obrigatório'),
  exibirBtnRemarcarParaProf: Yup.string().required('Obrigatório'),
  endereco: Yup.object().shape({
    cep: Yup.string().required('Obrigatório'),
    logradouro: Yup.string().required('Obrigatório'),
    estado: Yup.string().required('Obrigatório'),
    numero: Yup.string().required('Obrigatório'),
    bairro: Yup.string().required('Obrigatório'),
    cidade: Yup.string().required('Obrigatório'),
  }),
  telefones: Yup.array()
    .min(1, 'É necessario informar pelo menos um telefone')
    .of(
      Yup.object().shape({
        numero: Yup.string().required('Obrigatório'),
        tipo: Yup.string().required('Obrigatório'),
      })
    ),
  listHorarioAtendimento: Yup.array()
    .min(1, 'É necessario informar pelo menos um horário de atendimento')
    .of(
      Yup.object().shape({
        inicio: Yup.string().required('Obrigatório'),
        fim: Yup.string().required('Obrigatório'),
      })
    ),
});

class EstabelecimentoForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModalWhatsapp: false,
      qrCode: '',
      statusWhatsapp: null,
    };
  }

  addTelefone = (setFieldValue, telefones) => {
    let newArray = _.concat(telefones, { numero: '', tipo: 'CELULAR' });
    setFieldValue('telefones', newArray);
  };

  removeItem = (setFieldValue, telefones, index) => {
    telefones.splice(index, 1);
    setFieldValue('telefones', telefones);
  };

  addItemInArray = (propName, defaultValue, values, setValues) => {
    let newValues = _.cloneDeep(values);
    newValues[propName].push(
      defaultValue ? defaultValue : { idList: new Date().getTime() }
    );
    setValues(newValues);
  };

  removeItemInArray = (propName, indexItem, values, setValues) => {
    let newValues = _.cloneDeep(values);
    if (newValues[propName].length > 1) {
      newValues[propName].splice(indexItem, 1);
      setValues(newValues);
    }
  };

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: '/estabelecimento/view/' + values.id,
      state: { entity: values },
    });
  };

  resetForm = (initialValues) => {
    this.formRef.resetForm(initialValues);
  };

  validaDias = (values) => {
    let _this = this;
    let promise = new Promise(function (resolve) {
      let horarios = values.listHorarioAtendimento;
      let response;
      for (let i = 0; i < horarios.length; i++) {
        let dias = horarios[i].dias;
        if (dias && dias.length > 0) {
          response = true;
          break;
        } else {
          alertActions.error('Selecione ao menos um dia para atendimento');
          response = false;
          break;
        }
      }
      resolve(response);
    });
    return promise;
  };

  saveForm = () => {
    let _this = this;

    let values = _this.formRef.state.values;

    // TODO corrigir data de proximo pagamento
    var d = new Date();
    d.setMonth(d.getMonth() + 3);
    _.set(values, 'dataProximoPagamento', d);

    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(values).then((error) => {
        if (_.isEmpty(error)) {
          _this.validaDias(values).then((response) => {
            if (response) {
              _this.props.loading(true);
              estabelecimentoService.doSave(values).then(
                (response) => {
                  _this.props.success({
                    message: `Estabelecimento, foi ${
                      !values.id ? 'criado' : 'alterado'
                    } com sucesso!`,
                  });
                  let id = values.id
                    ? values.id
                    : defaultService.getIdFromUrl(response.headers.location);
                  values.id = id;
                  _this.props.loading(false);
                  _this.goToViewEntity(values);
                  resolve(true);
                },
                (erros) => {
                  _this.props.loading(false);
                  _this.props.error({
                    message: 'Não foi possível salvar estabelecimento.',
                  });
                  try {
                    let response = erros.response.data;
                    if (response && response.messages) {
                      for (var i = 0; i < response.messages.length; i++) {
                        let erroItem = response.messages[i];
                        _this.formRef.setFieldError(
                          erroItem.fieldName,
                          translate(erroItem.message.code)
                        );
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                  console.log(error);
                }
              );
            } else {
              resolve(false);
            }
          });
        } else {
          console.log(error);
          if (_.get(error, 'telefones')) {
            alertActions.error('Preencha ao menos um telefone de contato');
          } else {
            alertActions.error('Campos obrigatórios não preenchidos');
          }
          resolve(false);
        }
      });
    });
    return promise;
  };

  logoUrl = (values) => {
    console.log(values);
    if (values.foto && values.foto.id) {
      return midiaService.url(values.foto.id);
    } else {
      if (values.foto) {
        return values.foto.midiaConteudoVO.foto64;
      } else {
        return imagemBG;
      }
    }
  };
  componentDidMount(): void {
    this.checkStatus();
  }

  verificaStatusWhatsapp = (event: any) => {
    whatsappService.checkStatus().then((response) => {
      if (response) {
        console.log(response);
        let status = response.data.status;
        if (!status) {
          this.conectarWhatsapp();
        }
      }
    });
  };

  conectarWhatsapp = () => {
    whatsappService.startSession().then((response) => {
      console.log(response);
      // let qrCode = response.data.qrcode;
      let qrCode = response.data.qrcode;
      if (qrCode) {
        this.setState({ qrCode }, () => {
          this.toggleModalWhatsapp();
        });
      }
      this.checkStatus();
    });
  };
  checkStatus = () => {
    whatsappService.checkStatus().then((response) => {
      if (response) {
        console.log(response);
        let statusWhatsapp = response.data.message;
        console.log(statusWhatsapp);
        if (statusWhatsapp) {
          this.setState({ statusWhatsapp }, () => {});
        }
      }
    });
  };
  gerarQrCode = () => {
    whatsappService.startSession().then((response) => {
      console.log(response);
      // let qrCode = response.data.qrcode;
      let qrCode = response.data.qrcode;
      this.setState({ qrCode }, () => {});
    });
  };
  desconectarWhatsapp = (event: any) => {
    event.preventDefault();
    whatsappService.disconnect().then((response) => {
      console.log(response);
      this.props.success({
        message: `Whatsapp desconectado com sucesso!`,
      });
    });
  };

  toggleModalWhatsapp = () => {
    this.setState(({ showModalWhatsapp }) => ({
      showModalWhatsapp: !showModalWhatsapp,
    }));
  };

  render() {
    let _this = this;
    const { viewMode } = this.props;
    return (
      <React.Fragment>
        <Formik
          enableReinitialize
          validationSchema={EstabelecimentoValidate}
          initialValues={this.props.entity}
          validateOnBlur={false}
          validateOnChange={false}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validationSchema,
            validateForm,
            setValues,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="form-inside">
                <div className="row section-form">
                  <div className="col-12 col-md-1">
                    <ImageInput
                      maxWidth={120}
                      maxHeight={120}
                      height={120}
                      required={true}
                      erroMensagem={values.foto}
                      onChange={(imageVO) => setFieldValue('foto', imageVO)}
                      dataUrl={this.logoUrl(values)}
                    />

                    {/* <Avatar
                      user={values}
                      namePropsMidiaId={'foto.id'}
                      editable={!viewMode}
                      onChange={(name, value) => setFieldValue('foto', value)}
                      cropRadius={0}
                      exportAsSquare={true}
                    ></Avatar> */}
                  </div>

                  <div className="col-12 col-md-11 form-row">
                    <FormGroup className="col-12 col-md-6 col-lg-5">
                      <InputViewEdit
                        component={FormInput}
                        label={'Nome'}
                        type="text"
                        id="nome"
                        name="nome"
                        placeholder="Digite o nome do estabelecimento"
                        value={values.nome}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.nome}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-6 col-lg-3">
                      <InputViewEdit
                        component={CpfCnpjInput}
                        label={'CPF ou CNPJ'}
                        type="text"
                        id="cpfCnpj"
                        name="cpfCnpj"
                        placeholder="Digite o CPF ou CNPJ"
                        value={values.cpfCnpj}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        erroMensagem={errors.cpfCnpj}
                        viewMode={viewMode}
                        required={true}
                        viewClassName={'font-weight-bold'}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-6 col-lg-4">
                      <InputViewEdit
                        component={FormInput}
                        label={'E-mail principal'}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Digite o e-mail principal"
                        value={values.email}
                        onChange={(name, value) => setFieldValue(name, value)}
                        noSize={true}
                        required={true}
                        viewMode={viewMode}
                        erroMensagem={errors.email}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-6 col-lg-2">
                      <InputViewEdit
                        component={FormSelectInput}
                        label={'Tipo de Integração'}
                        id="tipoIntegracao"
                        name="tipoIntegracao"
                        placeholder="Tipo de integração"
                        value={_.get(values, 'tipoIntegracao')}
                        onChange={setFieldValue}
                        noSize={true}
                        erroMensagem={_.get(errors, 'tipoIntegracao')}
                        defaultValue={_.get(
                          values,
                          'tipoIntegracao.description',
                          ' '
                        )}
                        viewMode={viewMode}
                        required={true}
                        returnFullObject={true}
                        valueKey={'name'}
                        labelKey={'description'}
                        multi={false}
                        options={objectsConstants.TIPO_INTEGRACAO}
                        onChange={(name, value) => {
                          setFieldValue(name, value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-6 col-lg-3">
                      <label className="label-nowrap">Enviar e-mails?</label>
                      <CheckBoxInputOnly
                        checked={values.enviarEmailPacientes}
                        defaultValue={true}
                        name={`enviarEmailPacientes`}
                        viewMode={viewMode}
                        id={'enviarEmailPacientes'}
                        className={'mt-2'}
                        helpText={
                          'Enviar e-mails para os pacientes no agendamento, cancelamento ou alteração de data/horários. Nos agendamentos on-line sempre serão enviados os e-mails'
                        }
                        label={`Enviar e-mails aos pacientes das marcações feitas pelo estabelecimento? `}
                        onChange={(checked, name) => {
                          setFieldValue(name, checked);
                        }}
                      ></CheckBoxInputOnly>
                    </FormGroup>
                    <FormGroup className="col-12 col-md-6 col-lg-3">
                      <InputViewEdit
                        component={SelectInput}
                        id={'exibirBtnCompareceuParaProf'}
                        options={[
                          { label: 'Sim', value: true },
                          { label: 'Não', value: false },
                        ]}
                        returnFullObject={true}
                        label="Exibir botão COMPARECEU para os profissionais? "
                        required={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        viewMode={viewMode}
                        name={'exibirBtnCompareceuParaProf'}
                        defaultValue={
                          values.exibirBtnCompareceuParaProf &&
                          values.exibirBtnCompareceuParaProf.value === false
                            ? 'Não'
                            : formatterHelper.getLabelEnum(
                                values.exibirBtnCompareceuParaProf &&
                                  values.exibirBtnCompareceuParaProf.value
                                  ? values.exibirBtnCompareceuParaProf.value
                                  : values.exibirBtnCompareceuParaProf
                                  ? values.exibirBtnCompareceuParaProf
                                  : false,
                                objectsConstants.SIM_NAO
                              )
                        }
                        value={
                          _.isBoolean(values.exibirBtnCompareceuParaProf)
                            ? formatterHelper.getEnum(
                                values.exibirBtnCompareceuParaProf,
                                objectsConstants.SIM_NAO
                              )
                            : values.exibirBtnCompareceuParaProf
                        }
                        onChange={(name, value) => {
                          setFieldValue(name, value.value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 col-md-6 col-lg-4">
                      <InputViewEdit
                        component={SelectInput}
                        id={'exibirBtnRemarcarParaProf'}
                        options={[
                          { label: 'Sim', value: true },
                          { label: 'Não', value: false },
                        ]}
                        returnFullObject={true}
                        label="Exibir botão REMARCAR para os profissionais? "
                        required={true}
                        labelKey={'label'}
                        valueKey={'value'}
                        viewMode={viewMode}
                        name={'exibirBtnRemarcarParaProf'}
                        defaultValue={
                          values.exibirBtnRemarcarParaProf &&
                          values.exibirBtnRemarcarParaProf.value === false
                            ? 'Não'
                            : formatterHelper.getLabelEnum(
                                values.exibirBtnRemarcarParaProf &&
                                  values.exibirBtnRemarcarParaProf.value
                                  ? values.exibirBtnRemarcarParaProf.value
                                  : values.exibirBtnRemarcarParaProf
                                  ? values.exibirBtnRemarcarParaProf
                                  : false,
                                objectsConstants.SIM_NAO
                              )
                        }
                        value={
                          _.isBoolean(values.exibirBtnRemarcarParaProf)
                            ? formatterHelper.getEnum(
                                values.exibirBtnRemarcarParaProf,
                                objectsConstants.SIM_NAO
                              )
                            : values.exibirBtnRemarcarParaProf
                        }
                        onChange={(name, value) => {
                          setFieldValue(name, value.value);
                        }}
                      />
                    </FormGroup>

                    <div className="col-12 form-row">
                      <p
                        className={`w-100 txt-black ml-2 mt-3 ${
                          viewMode ? '' : 'font-weight-bold'
                        }`}
                      >
                        Integração com Whatsapp
                      </p>
                      <FormGroup className="col-12 col-md-3 col-lg-3 pt-1">
                        <label className="label-wrap">Status:</label>
                        <p>{_this.state.statusWhatsapp}</p>
                      </FormGroup>
                      <FormGroup className="col-12 col-md-4 col-lg-4 pt-1">
                        <label className="label-nowrap">
                          Enviar solicitação de confirmação do agendamento?
                        </label>
                        <CheckBoxInputOnly
                          checked={values.enviarWhatsappMarcacao}
                          defaultValue={true}
                          name={`enviarWhatsappMarcacao`}
                          viewMode={viewMode}
                          id={'enviarWhatsappMarcacao'}
                          className={'mt-2'}
                          helpText={
                            'Enviar solicitação de confirmação do agendamento ao paciente pelo Whatsapp.'
                          }
                          label={
                            ' Enviar solicitação de confirmação do agendamento pelo  Whatsapp (24h antes)?'
                          }
                          onChange={(checked, name) => {
                            setFieldValue(name, checked);
                          }}
                        ></CheckBoxInputOnly>
                      </FormGroup>
                      <FormGroup className="col-12 col-md-4 col-lg-4 pt-1">
                        <label className="label-nowrap">
                          Enviar receituário ao salvá-lo?
                        </label>
                        <CheckBoxInputOnly
                          checked={values.enviarWhatsappReceituario}
                          defaultValue={true}
                          name={`enviarWhatsappReceituario`}
                          viewMode={viewMode}
                          id={'enviarWhatsappReceituario'}
                          className={'mt-2'}
                          helpText={
                            'Envio automático do receituário para o pacientes no salvamento da anamnese.'
                          }
                          label={`Enviar receituário no Whatsapp para os pacientes no salvamento da anamnese? `}
                          onChange={(checked, name) => {
                            setFieldValue(name, checked);
                          }}
                        ></CheckBoxInputOnly>
                      </FormGroup>
                      <div className="col-12 col-lg-4 pt-1">
                        <button
                          disabled={viewMode}
                          className="btn btn-success"
                          onClick={this.conectarWhatsapp}
                        >
                          Conectar Whatsapp
                        </button>

                        <button
                          disabled={viewMode}
                          className="btn btn-danger"
                          onClick={this.desconectarWhatsapp}
                        >
                          Desconectar Whatsapp
                        </button>
                      </div>
                    </div>
                  </div>
                  {this.state.showModalWhatsapp && (
                    <ModalWhatsapp
                      toogle={this.toggleModalWhatsapp}
                      isOpen={this.state.showModalWhatsapp}
                      qrCode={this.state.qrCode}
                      gerarQrCode={this.gerarQrCode}
                      verificaStatusWhatsapp={this.verificaStatusWhatsapp}
                    />
                  )}
                </div>
              </div>
              <h2 className="w-100">Cooperativa</h2>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-md-6 ">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Cooperativa'}
                      id="cooperativa"
                      name="cooperativa"
                      placeholder="cooperativa"
                      value={_.get(values, 'cooperativa')}
                      defaultValue={
                        _.get(values, 'cooperativa')
                          ? _.get(values, 'cooperativa').nome
                          : '--'
                      }
                      onChange={setFieldValue}
                      noSize={true}
                      erroMensagem={_.get(errors, 'cooperativa')}
                      viewMode={viewMode}
                      required={false}
                      returnFullObject={true}
                      valueKey={'id'}
                      labelKey={'nome'}
                      multi={false}
                      service={cooperativaService.findAll}
                      sortKey={'nome '}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-6 ">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'Tipo de Faturamento'}
                      id="faturamento"
                      name="tipoFaturamento"
                      placeholder="Tipo de faturamento"
                      value={_.get(values, 'tipoFaturamento')}
                      onChange={setFieldValue}
                      noSize={true}
                      erroMensagem={_.get(errors, 'tipoFaturamento')}
                      viewMode={viewMode}
                      required={false}
                      returnFullObject={true}
                      valueKey={'name'}
                      labelKey={'description'}
                      defaultValue={_.get(
                        values,
                        'tipoFaturamento.description',
                        ' '
                      )}
                      multi={false}
                      options={objectsConstants.TIPO_FATURAMENTOS}
                    />
                  </FormGroup>
                </div>
              </div>
              {/* ENDEREÇO */}
              {/* <hr></hr> */}

              <h2 className="w-100">Endereço</h2>
              <div className="form-inside">
                <div className="row section-form">
                  <FormGroup className="col-12 col-md-3">
                    <InputViewEdit
                      component={ZipCodeInput}
                      label="CEP"
                      id="enderecoId"
                      name="endereco.id"
                      value={_.get(values, 'endereco.cep')}
                      erroMensagem={errors.endereco && errors.endereco.cep}
                      required={true}
                      placeholder="CEP"
                      required={true}
                      onChange={(name, value) => {
                        if (values.endereco) {
                          value.id = values.endereco.id;
                        }

                        value.estado = value.uf;
                        setFieldValue('endereco', value);
                      }}
                      returnFullCity={false}
                      viewMode={viewMode}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-7">
                    <InputViewEdit
                      component={FormInput}
                      label={'Logradouro'}
                      placeholder="Digite o logradouro"
                      onChange={(name, value) => setFieldValue(name, value)}
                      noSize={true}
                      erroMensagem={errors.logradouro}
                      viewMode={viewMode}
                      required={true}
                      id="enderecoLogradouro"
                      value={_.get(values, 'endereco.logradouro')}
                      name="endereco.logradouro"
                      erroMensagem={_.get(errors, 'endereco.logradouro')}
                    />
                  </FormGroup>
                  <FormGroup className="col-3 col-md-2">
                    <InputViewEdit
                      component={FormInput}
                      type="text"
                      label="Nº"
                      placeholder="Nº"
                      onChange={setFieldValue}
                      noSize={true}
                      viewMode={viewMode}
                      id="endereconumero"
                      value={_.get(values, 'endereco.numero')}
                      name="endereco.numero"
                      erroMensagem={_.get(errors, 'endereco.numero')}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-9 col-md-3">
                    <InputViewEdit
                      component={FormInput}
                      label="Complemento"
                      type="text"
                      placeholder="Complemento"
                      erroMensagem={errors.complemento}
                      onChange={setFieldValue}
                      noSize={true}
                      viewMode={viewMode}
                      id="enderecocomplemento"
                      value={_.get(values, 'endereco.complemento')}
                      name="endereco.complemento"
                      erroMensagem={_.get(errors, 'endereco.complemento')}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 col-md-3">
                    <InputViewEdit
                      component={FormInput}
                      label="Bairro"
                      type="text"
                      placeholder="Bairro"
                      erroMensagem={errors.bairro}
                      onChange={setFieldValue}
                      noSize={true}
                      viewMode={viewMode}
                      id="enderecobairro"
                      value={_.get(values, 'endereco.bairro')}
                      name="endereco.bairro"
                      erroMensagem={_.get(errors, 'endereco.bairro')}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-8 col-md-4">
                    <InputViewEdit
                      component={FormInput}
                      label="Cidade"
                      type="text"
                      placeholder="Cidade"
                      erroMensagem={errors.cidade}
                      onChange={setFieldValue}
                      noSize={true}
                      viewMode={viewMode}
                      id="enderecocidade"
                      value={_.get(values, 'endereco.cidade')}
                      name="endereco.cidade"
                      erroMensagem={_.get(errors, 'endereco.cidade')}
                      required={true}
                    />
                  </FormGroup>
                  <FormGroup className="col-4 col-md-2">
                    <InputViewEdit
                      component={FormSelectInput}
                      label={'UF'}
                      id="uf"
                      name="endereco.estado"
                      placeholder="UF"
                      value={_.get(values, 'endereco.estado')}
                      onChange={setFieldValue}
                      noSize={true}
                      erroMensagem={_.get(errors, 'endereco.estado')}
                      viewMode={viewMode}
                      required={true}
                      returnFullObject={false}
                      valueKey={'sigla'}
                      labelKey={'sigla'}
                      multi={false}
                      service={ApiIBGE.findAllEstados}
                      sortKey={'sigla'}
                    />
                  </FormGroup>
                </div>
              </div>

              {/* TELEFONES */}
              {/* <hr></hr> */}

              <h2 className="w-100">Telefones</h2>
              <div className="form-inside">
                {/* <div className="row section-form"> */}
                {values.telefones &&
                  values.telefones.map((telefone, index) => {
                    return (
                      <div className="row section-form" key={index}>
                        <MultiTelefones
                          entity={values}
                          index={index}
                          viewMode={viewMode}
                          size={values.telefones.length}
                          handleChange={handleChange}
                          errors={errors}
                          propName={`telefones[${index}]`}
                          onChange={setFieldValue}
                          size={values.telefones.length}
                          removeItem={() => {
                            this.removeItemInArray(
                              `telefones`,
                              index,
                              values,
                              setValues
                            );
                          }}
                        ></MultiTelefones>
                      </div>
                    );
                  })}
                {/* </div> */}
                <div
                  className={`form-row justify-content-md-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    onClick={() =>
                      this.addTelefone(setFieldValue, values.telefones)
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Adicionar outro telefone
                  </button>
                </div>
              </div>
              {/* HORARIO DE FUNCIONAMENTO */}
              {/* <hr></hr> */}

              <h2 className="w-100">Horário de funcionamento</h2>
              <div className="form-inside">
                <div className="row section-form flex-column">
                  {values.listHorarioAtendimento &&
                    values.listHorarioAtendimento.map((horario, index) => {
                      return (
                        <React.Fragment key={index}>
                          <HorarioFuncionamento
                            key={horario.id ? horario.id : horario.idList}
                            tipoHorarioFuncionamento={
                              objectsConstants.TIPO_HORARIO_FUNCIONAMENTO_ESTABELECIMENTO
                            }
                            entity={horario}
                            index={index}
                            viewMode={viewMode}
                            size={values.listHorarioAtendimento.length}
                            propName={`listHorarioAtendimento[${index}]`}
                            onChange={setFieldValue}
                            errors={errors}
                            periodicidade={false}
                            removeItem={() => {
                              this.removeItemInArray(
                                `listHorarioAtendimento`,
                                index,
                                values,
                                setValues
                              );
                            }}
                          ></HorarioFuncionamento>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="form-inside">
                <div
                  className={`w-100 justify-content-lg-start justify-content-center pl-10px ${
                    viewMode ? 'd-none' : ''
                  }`}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      this.addItemInArray(
                        'listHorarioAtendimento',
                        null,
                        values,
                        setValues
                      )
                    }
                  >
                    Adicionar outro horário
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
  forwardRef: true,
})(EstabelecimentoForm);
