import React from 'react';
import { FormGroup } from 'reactstrap';
import CheckBoxInputOnly from '../../components/inputs/CheckBoxInputOnly';
import CpfCnpjInput from '../../components/inputs/CpfCnpjInput';
import { userService } from '../../_services/user.service';
import FormInput from '../../components/inputs/FormInput';
import InputViewEdit from '../../components/inputs/InputViewEdit';
import FormSelectInput from '../../components/inputs/FormSelectInput';
import { medicoService } from '../../_services/medico.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class PacienteListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    const isMedico = userService.isMedico();

    return (
      <React.Fragment>
        <FormGroup className="col-12 col-xl-4">
          <FormInput
            label="Nome do paciente"
            placeholder={'Nome'}
            id={'namefilter'}
            name="nome"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4 offset-sm-1'}
            value={entity.nome}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-4">
          <CpfCnpjInput
            label="CPF ou CNPJ"
            placeholder={'CPF ou CNPJ'}
            id={'cpfCnpj'}
            name="cpfCnpj"
            onChange={this.props.onChangeDate}
            type={'number'}
            size={12}
            style={'col-6 col-sm-2'}
            value={entity.cpfCnpj}
          />
        </FormGroup>
        <FormGroup className="col-12 col-xl-4">
          <FormInput
            label="E-mail"
            placeholder={'E-mail'}
            id={'email'}
            name="email"
            onChange={this.props.onChangeDate}
            type={'text'}
            size={12}
            style={'col-12 col-sm-4'}
            value={entity.email}
          />
        </FormGroup>
        {isMedico && (
          <FormGroup className="col-12 col-xl-4">
            <InputViewEdit
              component={FormSelectInput}
              onChange={(name, value) => {
                this.props.onChangeDate(name, value);
              }}
              id={`estabelecimentoMedico`}
              label="Estabelecimento"
              name={`estabelecimentoMedico`}
              required={entity.pacientesEstabelecimento}
              value={entity.estabelecimentoMedico}
              returnFullObject={true}
              service={medicoService.findByEspecialidade}
              creatable={false}
              isMulti={false}
              valueKey={'estabelecimento.id'}
              labelKey={'estabelecimento.nome'}
            />
          </FormGroup>
        )}
        <FormGroup className={`col-12 col-xl-4 ${isMedico ? 'mt-4' : ''}`}>
          <CheckBoxInputOnly
            checked={entity.pacientesEstabelecimento}
            name={`pacientesEstabelecimento`}
            label={`Somente pacientes atendidos no estabelecimento`}
            onChange={(checked, name) => {
              this.props.onChangeDate(name, checked);
            }}
          />
        </FormGroup>
        <FormGroup className={`col-12 ${isMedico ? 'd-flex flex-row' : ''}`}>
          <CheckBoxInputOnly
            checked={entity.nomeExato}
            name={`nomeExato`}
            label={`Buscar nome exato`}
            onChange={(checked, name) => {
              this.props.onChangeDate(name, checked);
            }}
          />
        </FormGroup>
      </React.Fragment>
    );
  }
}
