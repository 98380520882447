//@flow
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { urlsConstants } from '../../../_constants/urls.constant';
import { formatterHelper } from '../../../_helpers/formatter.helper';
import { dateHelper } from '../../../_helpers/date.helper';
import { defaultService } from '../../../_services/defaultService';
import { marcacaoService } from '../../../_services/marcacao.service';
import Avatar from '../../../components/Avatar/Avatar';
import anexo from '../../../images/icon_anexo_BIG.svg';
import email from '../../../images/icones/email-black-envelope.svg';
import zap from '../../../images/icones/whatsapp_icon_151518.svg';
import './CabecalhoPaciente.scss';
import { objectsConstants } from '../../../_constants/objects.constants';

let download = require('downloadjs');

type Props = {
  dataHora: any,
  newDiv?: boolean,
  onCancelar: any,
  onEdit?: any,
  isAnamneseEditing: boolean,
  dispatch: any,
  entity: any,
  viewMode: any,
};

class CabecalhoPaciente extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listAnexos: [],
    };
  }

  componentDidMount() {
    this.loadListAnexoMarcacao();
  }

  downLoadFile = (anexo: any) => {
    if (anexo.id) {
      let url = urlsConstants.URL_MIDIAS + 'vo/' + anexo.id;
      defaultService.doGet(url).then((result) => {
        let dataFile = result.data;
        if (dataFile) {
          let uri = `data:${dataFile.tipo};base64,${dataFile.data}`;
          if (dataFile.data.includes('base64,')) {
            uri = dataFile.data;
          }

          download(uri, dataFile.nome, dataFile.tipo);
        }
      });
    }
    return false;
  };
  loadListAnexoMarcacao = () => {
    const { marcacao } = this.props;
    if (marcacao) {
      marcacaoService.findAnexosByMarcacao(_.get(marcacao, 'id')).then(
        (response) => {
          let listAnexos = response.data;
          this.setState({ listAnexos: listAnexos });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  defineSexo = (sexo) => {
    if (sexo === 'M') {
      return '- Masculino';
    } else if (sexo === 'F') {
      return '- Feminino';
    } else {
      return '- Não especificado';
    }
  };

  getDadosConvenio = (dadosConvenio, tipoAtendimento) => {
    if (!dadosConvenio) {
      return 'Dados do convênio não informados.';
    }
    if (tipoAtendimento == objectsConstants.TIPO_ATENDIMENTO_CORTESIA) {
      return 'Cortesia';
    }

    if (tipoAtendimento == objectsConstants.TIPO_ATENDIMENTO_PARTICULAR) {
      return 'Atendimento Particiular';
    }
    const { convenio, numeroCarteirinha, dataValidadeCarteirinha } =
      dadosConvenio;

    const html = `
    <b>Convênio:</b> ${_.get(convenio, 'sigla')}
    <b> Carteirinha:</b> ${numeroCarteirinha || 'Não informada'}
    <b> Validade:</b> ${
      dateHelper.format(dataValidadeCarteirinha, { mode: 'DATE' }) ||
      'Não informada'
    }
  `;

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  getDadosEndereco = (dadosEndereco) => {
    if (!dadosEndereco) {
      return 'Endereço não informado.';
    }
    const { logradouro, numero, complemento, bairro, cidade, estado, cep } =
      dadosEndereco;

    const endereco = [
      logradouro,
      numero && `, ${numero}`,
      complemento && ` ${complemento}`,
      bairro && ` - ${bairro}`,
      cidade && ` | ${cidade}`,
      estado && `/${estado}`,
    ]
      .filter(Boolean)
      .join('');

    const html = `
        <b>Endereço:</b> ${endereco || 'Não informado'}
        <br />
        <b>CEP:</b> ${cep || 'Não informado'}
      `;

    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  };

  render() {
    const { paciente, agendamentosHoje, marcacao } = this.props;
    console.log('paciente Cabeçalho', paciente);

    return (
      <React.Fragment>
        <div className="row d-flex align-items-center justify-content-center cabecalhoFundo">
          <div className="col-12 col-md-1 my-3 my-sm-0 d-flex align-items-center justify-content-center cabecalho-paciente-avatar">
            <Avatar user={paciente} />
          </div>
          <div className="col-12 col-md-4 d-flex flex-column align-items-center align-items-sm-start justify-content-center dadosPaciente">
            <div className="cabecalho-paciente-nome">
              {paciente.nomeSocial ? paciente.nomeSocial : paciente.nome}
            </div>
            <div className="d-flex align-items-center">
              <div className="cabecalho-paciente-data">
                {moment(paciente.dataNascimento, 'YYYY-MM-DD').format(
                  'DD/MM/YYYY'
                )}
              </div>
              <div className="mr-1">
                ({moment().diff(paciente.dataNascimento, 'years', false)} anos)
              </div>
              <div className="mr-1">{this.defineSexo(paciente.sexo)}</div>
            </div>
            <div className="d-flex align-items-center">
              {this.getDadosConvenio(
                paciente.pacienteConvenios[0],
                paciente.tipoAtendimento
              )}
            </div>
            <div className="d-flex align-items-center">
              <div className="cabecalho-paciente-ico-text mr-2">
                {' '}
                <img src={email} alt={'email'} />
                {paciente.email}{' '}
              </div>

              <div className="cabecalho-paciente-ico-text">
                <img src={zap} alt={'telefone'} />
                {paciente.telefones && paciente.telefones.length > 0
                  ? paciente.telefones[0].numero
                  : ''}
              </div>
            </div>
            <div className="d-flex align-items-center">
              {this.getDadosEndereco(paciente.endereco)}
            </div>
          </div>
          <div className="col-12 col-md-2 d-flex flex-column align-items-center align-items-sm-start justify-content-center infoPaciente">
            <div className="info-paciente-cabecalho">
              <b>Informações sobre o paciente: </b>
              {paciente.informacao ? (
                paciente.informacao.replace(/<\/?p>/g, '')
              ) : (
                <i>Nenhuma informação foi adicionada!</i>
              )}
            </div>
          </div>
          <div className="col-12 col-md-3 d-flex flex-column align-items-center align-items-sm-center justify-content-center justify-content-sm-center list-anexo">
            <div className="list-anexo-paciente">
              <span>
                <img className="list-anexo-icon" src={anexo} alt="anexo" />
              </span>
              Arquivos enviados pelo paciente
            </div>
            {this.state.listAnexos &&
              this.state.listAnexos.map((anexo, i) => {
                return (
                  <div className="row w-100" key={anexo.id ? anexo.id : i}>
                    <div className="col-12 d-flex flex-row align-items-center">
                      <div
                        className="nome-anexo flex-fill"
                        onClick={() => {
                          this.downLoadFile(anexo);
                        }}
                      >
                        {anexo.nome}
                      </div>
                      <div className="size-anexo disable">
                        {formatterHelper.getFileSize(anexo.tamanho)}
                      </div>
                    </div>
                    <div className="col-12 desc-anexo">
                      {/* <b>Descrição: </b> */}
                      {anexo.descricao}
                    </div>
                    <hr></hr>
                  </div>
                );
              })}
          </div>
          <div className="col-12 col-md-2 d-flex flex-column align-items-center  justify-content-start justify-content-sm-center ">
            {agendamentosHoje &&
              agendamentosHoje.length > 0 &&
              agendamentosHoje[0].tipoAtendimento == 'PARTICULAR' && (
                <label className="lbl-tipo-atendimento">
                  Atendimento Particular
                </label>
              )}
            {agendamentosHoje &&
              agendamentosHoje.length > 0 &&
              agendamentosHoje[0].tipoAtendimento == 'CORTESIA' && (
                <label className="lbl-tipo-atendimento">Cortesia</label>
              )}
            {agendamentosHoje &&
              agendamentosHoje.length > 0 &&
              agendamentosHoje[0].tipoAtendimento == 'CONVENIO' && (
                <label className="lbl-tipo-atendimento">
                  {_.get(agendamentosHoje[0], 'convenio.nome', 'Convênio')} -{' '}
                  {_.get(_.get(agendamentosHoje[0], 'planoConvenio'), 'nome')}
                </label>
              )}
          </div>
        </div>
        <br></br>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { isAnamneseEditing } = state.anamnese;
  return {
    isAnamneseEditing,
  };
}
export default connect(
  mapStateToProps,
  null,
  null
)(withRouter(CabecalhoPaciente));
