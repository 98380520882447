//@flow
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import React, { PureComponent } from 'react';
import { InputType } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { MyCustomUploadAdapterPlugin } from '../UploadAdapter/UploadAdapter'


type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  placeholder: string,
  removePlugins: string[],
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string
};


export default class FormTextCKEditor extends PureComponent<Props> {
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false

  };

  getInput = () => {
    return this._input;
  };

  getRefCkEditor = () => {
    return this.refCkEditor;
  }

  render() {
    let { messageValidate, name, } = this.props;

    // LISTAR TODOS OS PLUGINS DO CK EDITOR
    // let plugins = ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName );
    // console.log(plugins);

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    const value = this.props.value;

    return (
      <div id={this.props.id}
        className={`${this.props.className && this.props.className}
             ${this.props.required ? "input-required" : ""} ${messageValidate ? 'is-invalid' : ''}`}>

        <CKEditor

          editor={ClassicEditor}
          onInit={(editor) => {
            this.editorInstance = editor;
            if (this.props.onInit) {
              this.props.onInit(editor);
            }
          }}
          data={value}
          config={{
            placeholder: this.props.placeholder,
            removePlugins: this.props.removePlugins,
            extraPlugins: [MyCustomUploadAdapterPlugin],
          }}
          ref={(ckRef) => {
            this.refCkEditor = ckRef;
          }}
          onBlur={this.props.onBlur}
          onChange={(event, editor) => {
            this.props.onChange(name, editor.getData());
          }}
          required={this.props.required}
          disabled={this.props.disabled ? this.props.disabled : false}
        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}