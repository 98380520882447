//@flow
import _ from 'lodash';
import { urlsConstants } from './../_constants/urls.constant';
import { oauthHeaderJson } from './../_helpers/oauth-header';
import type { filtro } from './../_types/filtro.type';
import type { medico } from './../_types/medico.type';
import { defaultService } from './defaultService';

export const atendimentoPrioritarioService = {
  findAll,
  findByFiltro,
  doGet,
  doSave,
  doDelete,
};

function findAll() {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO_PRIORITARIO}findAll`
  );
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ATENDIMENTO_PRIORITARIO}list`,
    options,
    filter
  );
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO_PRIORITARIO}${id}`);
}

function doSave(atendimentoPrioritario) {
  return defaultService.doSave(
    `${urlsConstants.ATENDIMENTO_PRIORITARIO}`,
    atendimentoPrioritario
  );
}

function doDelete(id: number) {
  const requestOptions = {
    method: 'DELETE',
    headers: oauthHeaderJson(),
  };
  var p: Promise<any> = fetch(
    urlsConstants.ATENDIMENTO_PRIORITARIO + id,
    requestOptions
  );
  return p;
}
