//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../../_constants/objects.constants';
import { atendimentoPrioritarioService } from '../../../_services/atendimentoPrioritario.service';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../../components/Topo/TopoListagem';
import PrioritarioForm from './PrioritarioForm';

const defaultEntity: medico = {
  nome: '',
};

class PrioritarioEdit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: defaultEntity,
    };
  }

  goToViewEntity = (values) => {
    this.props.history.push({
      pathname: 'parametrizacaoSuporte/atendimentoPrioritario/' + values.id,
      state: { entity: values },
    });
  };

  // handleCancel = () => {
  //   this.props.history.push('/usuario');
  // }

  handleCancel = () => {
    console.log('cancel usuario');
    this.props.history.goBack();
  };

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.loading(true);
      atendimentoPrioritarioService.doGet(this.props.match.params.id).then(
        (response) => {
          let ap = response.data.data;
          console.log(ap);

          this.setState({ entity: ap }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  }
  handleChangeProfile = (name, value) => {
    const entity = _.cloneDeep(this.state.entity);
    console.log(entity);
    _.set(entity, name, value);
    this.setState({ entity });
    console.log(entity);
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        console.log(erros);
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={`${
            entity.id ? 'Editar ' : 'Novo '
          }  atendimento prioritário`}
          canBack={true}
          backUrl={!entity.id ? null : '/atendimentoPrioritario'}
        />
        <TopoListagem
          showIndisponibilidade={false}
          showfilter={false}
        ></TopoListagem>

        <div className="flex-column">
          <FullCustomCard
            ref={(form) => {
              this.refFormComponent = form;
            }}
            permissionSave={objectsConstants.MENU_SUPORTE}
            title="Atendimento Prioritário"
            Componente={PrioritarioForm}
            viewMode={false}
            entity={entity}
            onCancel={this.handleCancel}
            redirectPath="/parametrizacaoSuporte/atendimentoPrioritario/"
            {...otherPops}
          ></FullCustomCard>
        </div>
        {entity.id && <div className="flex-column"></div>}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
  authentication: { refreshUser },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
  refreshUser: (payload) => refreshUser(payload),
});

export default withRouter(
  connect(mapStateToProps, mapDispatch, null)(PrioritarioEdit)
);
