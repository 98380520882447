//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import { objectsConstants } from '../../../_constants/objects.constants';
import { tableHelper } from '../../../_helpers/tableHelper';
import { atendimentoPrioritarioService } from '../../../_services/atendimentoPrioritario.service';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import ListagemPage from '../../../components/templates/ListagemPage';
import TopoListagem from '../../../components/Topo/TopoListagem';
import PrioritarioListFilter from './PrioritarioListFilter';

const columns = [
  {
    path: 'icone',
    title: 'Icone',
    isKey: false,
    isAvatar: true,
    dataSort: false,
    propsMidiaId: 'icone.id',
    className: 'avatar-list-icon-prioridade',
    width: '150px',
    notExport: true,
  },
  {
    path: 'nome',
    pathFilter: 'nome',
    title: 'Nome do atendimento prioritário',
    isKey: true,
    dataSort: true,
    className: 'column-bold uppercase',
  },

  {
    path: 'descricao',
    pathFilter: 'descricao',
    title: 'Descrição',
    isKey: false,
    dataSort: true,
  },
];

const endPoint = '/parametrizacaoSuporte/atendimentoPrioritario/novo';
const endPointView = '/parametrizacaoSuporte/atendimentoPrioritario/';
class PrioritarioListagemPage extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.path;
      }
    );

    let labels = _.map(
      _.filter(columns, function (coluna) {
        return !coluna.notExport;
      }),
      function (coluna) {
        return coluna.title;
      }
    );
    this.state = {
      labels,
      fields,
    };
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
        width={column.width}
        className={column.classname}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension,
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={
            objectsConstants.TITULO_PARAMETRIZACAO_ATENDIMENTO_PRIORITARIO
          }
          subTitle=" "
          canBack={false}
        />

        <TopoListagem
          securityActionDo={objectsConstants.VIEW}
          securityActionOn={objectsConstants.MENU_SUPORTE}
          buttonActionClassName={'btn-light'}
          buttonAction={() => {
            this.handleNewButton();
          }}
          securityActionIcon={'icon-btn-add'}
          securityActionName={'Novo atendimento prioritário'}
        ></TopoListagem>
        <ListagemPage
          title={`Parâmetros atendimento prioritário`}
          showNewButton={false}
          delete={() => {}}
          service={atendimentoPrioritarioService}
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          // FormFilter={PrioritarioListFilter}
          fields={this.state.fields}
          controller="api/atendimentoPrioritario"
          labels={this.state.labels}
          columns={columns}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(PrioritarioListagemPage);
