import _ from 'lodash';
import React from 'react';
import Avatar from '../../components/Avatar/Avatar';
import { dateHelper } from '../../_helpers/date.helper';
import { userService } from '../../_services/user.service';
import { objectsConstants } from '../../_constants/objects.constants';
import { midiaService } from '../../_services/midia.service';

type Props = {
  idUser?: any,
  user?: any,
};
type State = {
  usuario: any,
};
class DadosUser extends React.Component<Props, State> {
  // _montado = false;
  constructor(props: Props) {
    super(props);
    this.state = {
      usuario: {},
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.user) {
      if (!this.props.user) {
        return true;
      }
      if (this.props.telemedicina !== nextProps.telemedicina) {
        return true;
      }
      if (this.props.user.id !== nextProps.user.id) {
        return true;
      }
      if (
        this.props.user.atendimentoPrioritarioList !==
        nextProps.user.atendimentoPrioritarioList
      ) {
        return true;
      }
      if (this.props.user.nome !== nextProps.user.nome) {
        return true;
      }
      if (this.props.user.dataNascimento !== nextProps.user.dataNascimento) {
        return true;
      }
      if (this.props.recorrencia !== nextProps.recorrencia) {
        return true;
      }
    } else if (nextProps.idUser) {
      if (!this.props.idUser) {
        return true;
      }
      if (this.props.idUser !== nextProps.idUser) {
        return true;
      }
    } else {
      if (this.props.user.id || this.props.idUser) {
        return true;
      }
    }
    return !_.isEqual(this.state, nextState);
  }

  componentDidUpdate(prevProps, prevState) {
    this.loadUsuario();
  }

  componentDidMount() {
    this.loadUsuario();
  }

  loadUsuario = () => {
    if (this.props.user) {
      this.setState({ usuario: this.props.user });
    } else if (this.props.idUser) {
      userService.doGet(this.props.idUser).then(
        (response) => {
          let usuario = response.data.data;
          this.setState({ usuario });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  getInfoSecundaria = (usuario) => {
    if (usuario.especialidades) {
      return usuario.especialidades
        .map((especialidade) => especialidade.nome)
        .join(', ');
    } else if (usuario.dataNascimento) {
      return dateHelper.format(_.get(usuario, `dataNascimento`), {
        mode: 'DATE',
      });
    }
  };

  render() {
    const { usuario } = this.state;
    const { telemedicina, exame, recorrencia } = this.props;
    return (
      <React.Fragment>
        {usuario && (
          <div className="dados-user d-flex align-items-center justify-content-start">
            <Avatar
              namePropsMidiaId={'foto.id'}
              user={usuario}
              editable={false}
            ></Avatar>
            <div className="d-flex flex-column pl-2 min-0">
              <p className="nome" title={usuario.nome}>
                {usuario.apelido
                  ? usuario.apelido
                  : usuario.nomeSocial
                  ? usuario.nomeSocial
                  : usuario.nome}
                {telemedicina ? ' (TELEMEDICINA)' : ''}
                {recorrencia ? ' (Marcação recorrente)' : ''}
                {exame && this.props.exibeNomeExame ? ` (${exame.nome})` : ''}
                {usuario &&
                  usuario.atendimentoPrioritarioList &&
                  usuario.atendimentoPrioritarioList.map(
                    (atendimentoPrioritario, index) => {
                      return (
                        <img
                          className="icon-atendimento-prioritario"
                          src={midiaService.url(
                            _.get(atendimentoPrioritario, 'icone.id')
                          )}
                          alt={_.get(atendimentoPrioritario, 'nome')}
                        ></img>
                      );
                    }
                  )}
              </p>
              <div
                className="info-secundaria"
                title={
                  this.props.getInfoSecundaria
                    ? this.props.getInfoSecundaria
                    : this.getInfoSecundaria(usuario)
                }
              >
                <React.Fragment>
                  {this.props.getInfoSecundaria
                    ? this.props.getInfoSecundaria
                    : this.getInfoSecundaria(usuario)}
                </React.Fragment>
              </div>
              {this.props.marcacao && this.props.marcacao.horaChegada && (
                <div className="info-secundaria">
                  <b>Horário de chegada: </b> {this.props.marcacao.horaChegada}
                  {this.props.marcacao.horaChegadaRecepcao && (
                    <React.Fragment>
                      <b>Horário de chegada na recepção: </b>{' '}
                      {this.props.marcacao.horaChegadaRecepcao}
                    </React.Fragment>
                  )}
                  {this.props.marcacao.senhaRecepcao && (
                    <React.Fragment>
                      <b>Horário de chegada na recepção: </b>{' '}
                      {this.props.marcacao.senhaRecepcao}
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default DadosUser;
