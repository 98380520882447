//@flow
import _ from 'lodash';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../../_constants/objects.constants';
import { atendimentoPrioritarioService } from '../../../_services/atendimentoPrioritario.service';
import FullCustomCard from '../../../components/FullCustomCard/FullCustomCard';
import TopoTitleComponente from '../../../components/PageTitle/TopoTitleComponente';
import TopoListagem from '../../../components/Topo/TopoListagem';
import PrioritarioForm from './PrioritarioForm';

class PrioritarioView extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: {},
    };
  }

  componentDidMount() {
    this.loadDados();
  }

  loadDados = () => {
    if (this.props.match.params.id) {
      this.props.loading(true);
      atendimentoPrioritarioService.doGet(this.props.match.params.id).then(
        (response) => {
          let ap = response.data.data;
          this.setState({ entity: ap }, () => {
            this.props.loading(false);
          });
        },
        (error) => {
          console.log(error);
          this.props.loading(false);
        }
      );
    }
  };
  handleCancel = () => {
    this.props.history.goBack();
  };

  handleDeleteItem = (index, entity) => {
    this.props.loading(true);
    atendimentoPrioritarioService.doDelete(entity.id).then(
      (response) => {
        this.props.success({
          message: `Atendimento Prioritário foi excluído com sucesso!`,
        });
        this.props.loading(false);
        this.props.history.push(
          '/parametrizacaoSuporte/atendimentoPrioritario'
        );
      },
      (error) => {
        console.log(error);
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível excluir atendimento prioritário .',
        });
      }
    );
  };

  handleChangeProfile = (name, value) => {
    const entity = _.cloneDeep(this.state.entity);
    console.log(entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleChange = (name, value) => {
    const entity = _.cloneDeep(this.refFormComponent.state.values);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  handleSubmit = (e) => {
    this.refFormComponent
      .validateForm(this.refFormComponent.values)
      .then((erros) => {
        console.log(erros);
        if (_.isEmpty(erros)) {
          this.refFormComponent.handleSubmit();
        }
      });
  };

  render() {
    const { entity } = this.state;
    const { ...otherPops } = this.props;
    return (
      <React.Fragment>
        {entity.id && (
          <React.Fragment>
            <TopoTitleComponente
              mainTitle={`${
                entity.id ? 'Editar ' : 'Novo '
              }  atendimento prioritário`}
              canBack={true}
              backUrl={
                !entity.id
                  ? null
                  : '/parametrizacaoSuporte/atendimentoPrioritario'
              }
            />
            <TopoListagem
              showIndisponibilidade={false}
              showfilter={false}
            ></TopoListagem>

            <div className="flex-column">
              <FullCustomCard
                permissionSave={objectsConstants.MENU_SUPORTE}
                ref={(form) => {
                  this.refFormComponent = form;
                }}
                title="Atendimento Prioritário"
                Componente={PrioritarioForm}
                viewMode={true}
                entity={entity}
                cancelForm={this.loadDados}
                onSave={this.loadDados}
                btnExcluir={true}
                removeItem={this.handleDeleteItem}
                redirectPath="/parametrizacaoSuporte/atendimentoPrioritario/"
                {...otherPops}
              ></FullCustomCard>
            </div>
            {<div className="flex-column"></div>}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(null, mapDispatch, null)(withRouter(PrioritarioView));
