//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrioritarioEdit from './paramPrioritario/PrioritarioEdit';
import PrioritarioView from './paramPrioritario/PrioritarioView';
import PrioritarioListagemPage from './paramPrioritario/PrioritarioListagemPage';

export default class ParametrizacaoSuporteIndex extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route
          path="/parametrizacaoSuporte/atendimentoPrioritario/novo"
          component={PrioritarioEdit}
        />
        <Route
          path="/parametrizacaoSuporte/atendimentoPrioritario/:id"
          component={PrioritarioView}
        />
        <Route
          path="/parametrizacaoSuporte/atendimentoPrioritario"
          component={PrioritarioListagemPage}
        />
      </Switch>
    );
  }
}
