export const messages = {
  UNEXPECTED_ERROR: 'Erro inesperado, contacte o administrador do sistema',
  USER_NOT_FOUND: 'Usuário não encontrado',
  NOT_FOUND: 'Não encontrado',
  CAST_ERROR: 'Erro ao converter',
  RECEIVE_NOT_FOUND: 'Destinatário não encontrado',
  SENDER_NOT_FOUND: 'Remetente não encontrado',
  EMPTY_REQUIRED_FIELD: 'Campo obrigatório',
  CNPJ_INVALID: 'CNPJ inválido',
  PHONE_INVALID: 'Telefone inválido',
  EMAIL_INVALID: 'E-mail inválido',
  INVALID_REQUEST: 'Requisição inválida',
  CPF_INVALID: 'CPF inválido',
  POSTO_NOT_FOUND: 'Posto Autorizado não encontrado',
  ERROR_CONVERT_FILE: 'Erro ao converter arquivo',
  NOT_PERMITED: 'Acesso não permitido',
  INVALID_CONFIRM_PASSWORD: 'As senhas informadas não correspondem',
  INVALID_CONFIRM_EMAIL: 'Os e-mails informados não correspondem',
  CNPJ_AREADY_REGISTERED: 'CNPJ já cadastrado',
  DOCUMENT_INVALID: 'Documento inválido',
  INVALID_INSCRICAO_ESTADUAL: 'Inscrição estadual inválida',
  CEP_INVALID: 'CEP inválido',
  CPF_AREADY_REGISTERED: 'Cpf já cadastrado',
  ConfirmEqualsConstraint: 'As senhas informadas não correspondem',
  EMAIL_AREADY_REGISTERED: 'E-mail já cadastrado',
  CPF_CNPJ_AREADY_REGISTERED: 'Cpf já cadastrado',
  NOME_AREADY_REGISTERED_FOR_PROCEDURE: 'Nome já cadastrado para esse procedimento',
  NAO_TEM_ESTABELECIMENTO_COOPERADO: "O cooperado não atende neste consultório",
  NAO_TEM_ESTABELECIMENTO_COOPERADO_CONVENIO: "O cooperado não atende este convênio neste consultório",
  DT_NASCIMENTO_MENOR_18: 'Idade menor que 18 anos',
  PERFIL_COOPERADO_NAO_SELECIONADO: "É necessário selecionar pelo menos um Cooperado para ser notificado",
  PERFIL_AUDITORES_NAO_SELECIONADO: "É necessário selecionar pelo menos um auditor para ser notificado",
  NENHUM_PERFIL_SELECIONADO: "É necessário selecionar pelo menos um perfil para ser notificado",
  ERRO_CRIAR_PENDENCIA: "Não foi possível criar pendência.",
  ERRO_CRIAR_REGRAS_CONVENIO: "Não foi possível salvar regras de convenio.",
  ERRO_CRIAR_CRONOGRAMA_CONVENIO: "Não foi possível salvar cronograma de faturamento para o convenio.",
  MAX_ATENDIMENTO_LOTE: "O Lote só pode conter no máximo 100 atendimentos.",
  NAO_TEM_EXECUTANTES_PROCEDIMENTO: "Não existe Identificação do(s) Profissional(is) Executante(s) para este procedimento ",
  CPF_CNPJ_INVALID: "CPF OU CNPJ inválido",
  HORARIO_ATENDIMENTO_CONFLITANTE: "Horário de atendimento conflitante",
  HORARIO_ATENDIMENTO_ESTABELECIMENTO_CONFLITANTE: "Este mesmo dia/horário já esta sendo usado em outro estabelecimento",
  DATA_END_BEFORE_NOW: "Data final deve ser maior que o dia de hoje",
  DATA_END_BEFORE_START: "Data final menor que a data inicial",
  HOUR_END_BEFORE_START: "Hora final menor que a hora inicial",
  PERIODO_INDISPONIBILIDADE_CONFLITANTE: "Período de indisponibilidade conflitante com outro.",

  //INICIO alertas criação marcacao
  HORARIO_INDISPONIVEL: "Horário não está mais disponível para agendamento.",
  MARCACAO_DUPLICADA: "Não foi possível efetuar a marcação, possivelmente o horário já foi preenchido.",
  MEDICO_NAO_ATENDE_CONVENIO_INFOMADO: "O médico não atende o convênio selecionado neste estabelecimento.",
  EXAME_NAO_ATENDE_CONVENIO_INFORMADO: "O exame não pode ser marcado para o convênio selecionado neste estabelecimento.",
  MEDICO_NAO_ATENDE_ESPECIALIDADE: "O médico não atende a especialidade selecionada.",
  NOME_NAO_PODE_SER_USADO: "O nome do paciente não pode ser utilizado.",
  MAXIMO_MARCACAO_MES: "O limite de marcações por mês para este convênio e medico, foi atingido para o período desse agendamento.",
  MAXIMO_MARCACAO_DIA_CONSULTA: "O limite máximo de consultas por dia para este convênio e médico foi atingido!",
  MAXIMO_MARCACAO_DIA_EXAME: "O limite máximo de exames por dia para este convênio e médico foi atingido!",
  LIMITE_RETORNO_ATINGIDO: "O limite de retorno para esse profissional nesse dia já foi atingido, tente em uma nova data",
  MEDICO_NAO_ATENDE_RETORNO_NO_HORARIO: "O profissional não atende retornos para o horário selecionado.",
  //FIM alertas criação marcacao

  EMAIL_NOT_FOUND: "E-mail não cadastrado, favor entrar em contato com o estabelecimento para atualizar seu cadastro!",
  CPF_NOT_FOUND: "O CPF digitado não foi encontrado",
  WAITING_PAYMENT: "Aguardando pagamento",
  NUMBER_OF_LICENSES_EXCEEDED: "O número de licenças para profissionais foi atingido!",
  DATE_AFTER_NOW: "A data/hora inserida não deve ser maior que hoje.",
  PRODUCT_LIST_FIELD: "Ao menos um produto deve ser adicionado",
  NOME_AREADY_REGISTERED_FOR_UNIDADE: "Unidade Padrão já existente",
};

export function translate(key) {
  return messages[key];
}
